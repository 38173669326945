import React,{useState,useEffect}from 'react'
import img from "../../assets/images/banner-image@2x.png"
import star from "../../assets/images/reviews-5-star.png"
import "./index.css"
import {  Typography } from '@mui/material';
import ReqButton from "../../components/button/reqButton"
import ChatButton from '../../components/button/chatButton';
import BannerBottomBar from '../../components/bannerBottomBar';
import ProProduct from '../../components/proPrduct';
import ProductCart from '../../components/productCart';
import AboutSection from '../../components/aboutSection';
import MessageSection from '../../components/messageSection';
import companyImage from "../../assets/images/bg-assets.png"
import Certification from '../../components/certificationSection';
import ReviewSection from '../../components/reviewSection';
import QuestionSection from '../../components/questionSection';
import ImageSection from '../../components/imageSection/imageSection';
import FeedBack from '../../components/feedBackSection';
import Consultation from '../../components/consSection';
import LocationSection from '../../components/locationSection';
import CompanySection from '../../components/companySelction';
import ModalLocation from '../../components/modal';
import { useSelector } from 'react-redux';

const FazalHomePage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const location = useSelector((state) => state.location)

  // Use useEffect to set the modal state based on the location
  useEffect(() => {
    // If location is not empty, set isModalOpen to false, otherwise true
    setIsModalOpen(!location);
  }, [location]); 
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  return (
    <div className='overFlow'>
      <div>
      <div className='banner-image'>
  <div className="banner-content-wrapper">
    <div className='banner-data-star'>
      <img src={star} alt="5 Star Rating" />
      <Typography fontSize={"14px"} fontWeight={600} color={"#FFFFFF"} sx={{ marginLeft: "5px" }}>
        ( Top-Notch Products With 10,000+ Happy And Satisfactory Farmers )
      </Typography>
    </div>
    <div className='banner-data-heading'>
      <Typography fontSize={"55px"} fontWeight={400} color={"#FFFFFF"} sx={{ letterSpacing: "1.1px" }}>
        YOUR SOURCE FOR THE BEST CROP SUPPLEMENTS!
      </Typography>
    </div>
    <div className='banner-data-para'>
      <Typography fontSize={"22px"} fontWeight={500} color={"#FFFFFF"} sx={{ maxWidth: "689px" }}>
        Boost your crop yields and quality with our premium supplements, trusted by scientists and farmers across Pakistan.
      </Typography>
    </div>
    <div className='banner-data-button'>
      <ReqButton text={"Request Free Consultation Now →"} color={"#000000"} className={"req-button"} />
    </div>
  </div>
</div>
    
         <BannerBottomBar />
      </div>
  
         {/* <div className='center-company'>
         <CompanySection />
         
         </div> */}
         <ProProduct type={"al-fazal"} />
         <ProProduct  type={"glee"}/>
         <ProProduct type={"Nongye"}/>
         <BannerBottomBar />
         <AboutSection />
         <MessageSection />
         <img src={companyImage} className='company-fazal-image'/>
         {/* <Certification Certification={true}/> */}
         <ReviewSection />
         <QuestionSection />
         <ImageSection />
         <div className='banner-Bottom-below'>
         <BannerBottomBar />
         </div>
        <FeedBack />
        <Consultation />
       <LocationSection />
       <BannerBottomBar />
       <ModalLocation isOpen={isModalOpen} onClose={handleCloseModal} />
    </div>
  )
}

export default FazalHomePage