import React from 'react'
import img from "../../assets/images/bannernon@2x.png"
import star from "../../assets/images/reviews-5-star.png"

import "./index.css"
import {  Typography } from '@mui/material';
import BannerBottomBar from '../../components/bannerBottomBar';
import ProProduct from '../../components/proPrduct';
import ReviewSection from '../../components/reviewSection';
import QuestionSection from '../../components/questionSection';
import ImageSection from '../../components/imageSection/imageSection';
import FeedBack from '../../components/feedBackSection';
import Consultation from '../../components/consSection';
import LocationSection from '../../components/locationSection';
import CompanySection from '../../components/companySelction'
import AboutUSCompany from '../../components/aboutUsCompany'
import VideoSection from '../../components/videoSection'
import Client from '../../components/certificationSection/clients'
import NongyeLogo from '../../assets/svg/nongyeLogo'
const NongyePage = () => {
  return (
    <div>
     <div className='banner-image-Nongye-main'>
      <div className='wrapper'>
      <div className='banner-image-Nongye'>
     <NongyeLogo/>
         </div>
         <div className='banner-data-star-Nongye'>
             <img src={star}/>
             <Typography fontSize={"14px"} fontWeight={600} color={"#FFFFFF"} sx={{marginLeft:"5px"}}>
             ( Top-Notch Products With 10,000+ Happy And Satisfactory Farmers )
             </Typography>
             </div>
             <div className='banner-data-heading-Nongye'>
            <Typography fontSize={"55px"} fontWeight={400} color={"#FFFFFF"} sx={{letterSpacing: "1.1px",maxWidth:"775px"}}>
            NONGYE PRODUCTS PROVIDES THE BEST SOLUTIONS FOR YOUR AGRICULTURE           </Typography>
            </div>
      </div>
     
         </div>
    
         <BannerBottomBar />
         <div className='Nongye-company-client'>
          <Client/>
         </div>
         {/* <div className='Nongye-company-center'>
         <CompanySection selected="Nongye" />
          </div> */}
       <AboutUSCompany  name={"Nongye"}/>
         <ProProduct type={"Nongye"} />
        
         <ReviewSection type={"Nongye"}/>
         <VideoSection />
         <QuestionSection type={"Nongye"}/>
         <ImageSection />
         <div className='banner-Bottom-below'>
         <BannerBottomBar />
         </div>
        <FeedBack />
      
        <Consultation />
       <LocationSection />
       <BannerBottomBar />
    </div>
  )
}

export default NongyePage