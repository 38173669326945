import React,{useState,useEffect}from 'react'
import CompanySection from '../../components/companySelction'
import AboutUSCompany from '../../components/aboutUsCompany'
import "./index.css"
import AboutSection from '../../components/aboutSection'
import { useSelector } from 'react-redux';
import ModalLocation from '../../components/modal'

const AboutUS = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const location = useSelector((state) => state.location)
  
    // Use useEffect to set the modal state based on the location
    useEffect(() => {
      // If location is not empty, set isModalOpen to false, otherwise true
      setIsModalOpen(!location);
    }, [location]); 
    const handleCloseModal = () => {
      setIsModalOpen(false);
    };
  return (
    <>
             <AboutSection />

       <div className='about-company-center'>
        
         <CompanySection selected="Glee" />
          </div>
          <ModalLocation isOpen={isModalOpen} onClose={handleCloseModal} />

    </>
  )
}

export default AboutUS