import React from 'react'
import "./index.css"
import vision from "../../assets/images/our-vision-image.png"
import {  Typography } from '@mui/material'

const AboutUSCompany = ({name}) => {
  return (
    <div className='wrapper'>

    <div className='about-us-company'>
        <div className='about-us-vison'>
          <div className='flex-column'>
          <div class="vision-item">
        <img src={vision} alt={"vison"}/>
        </div>
        <div className="mission-statement"> 
        <Typography fontSize={"20px"} fontWeight={"700"} color={"#000"} >Our Mission</Typography>
        <Typography fontSize={"16px"} fontWeight={"400"} color={"#000"}  mt={"15px"}>{name} International (Private) Limited business will grow and perform through persistent customer services, innovation, product quality, and commitment of our employees in <strong>
        Agro Chemicals, Plant nutrient solutions and Efficient Irrigation Systems.
          </strong></Typography>
      </div>
          </div>
       
        <div className='about-us-vison-text'>
        <Typography fontSize={"35px"} fontWeight={"700"} color={"#00000"} >
        Our Vision
        </Typography>
        <Typography fontSize={"16px"} fontWeight={"400"} color={"#00000"}  mt={"18px"}>
        The primary focus of <strong>{name} International (Private) Limited</strong> has been the <strong>supply of farm inputs</strong>, including pesticides, fertilizers and provision of consultancy services to farmers for output optimization. Years of working experience with the farming community, as well as constant awareness of the evolution of agriculture at international and local levels, have led {name} International (Private) Limited to identify critical areas of development based on technological advancement. Over the years, we have also gained the ability to secure access to pertinent products and knowledge. Water-Soluble Fertilizers, Bio-Organic Stimulants, Plant Growth Promoting Bacteria - Fortified Fertilizer products and High Efficiency Irrigation Systems are some of the new ventures addressed by {name} International (Private) Limited in collaboration with some of the most prestigious partners worldwide.
        </Typography>
        <Typography fontSize={"16px"} fontWeight={"400"} color={"#00000"}  mt={"20px"}>
        We provide <strong>complete agricultural solutions</strong> through our products and <strong>farm advisory services</strong>, offering expert guidance for excellent yields. Our wide network of offices and warehouses across Pakistan provide our customers with complete agricultural solutions at their doorstep, ensuring ease and convenience.
        </Typography>
        <div className='link-cotton'>
        <Typography fontSize={"16px"} fontWeight={"400"} color={"#009035"} >
        {name} International Services Quality Policy
        </Typography>
      </div>
        </div>
      
        </div>
    </div>

    </div>
  )
}

export default AboutUSCompany