import { Typography, useMediaQuery } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import Logo from '../../assets/svg/logo';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import "./index.css";
import NavBarIcon from '../../assets/svg/NavBarIcon';
import GleeLogo from '../../assets/svg/gleeLogo';
import NongyeLogoGreen from '../../assets/svg/nongyeLogoGreen';
import CQILogoGreen from '../../assets/svg/CQILogoGreen';

const Navbar = () => {
    const navigate = useNavigate();
    const [selected, setSelected] = useState('');
    const [toggleNavBar, setToggleNavBar] = useState(false);
    const isSmallScreen = useMediaQuery('(max-width: 970px)');
    const navRef = useRef(null);

    const handleToggleNavBar = (event) => {
        event.preventDefault();

        event.stopPropagation();
        setToggleNavBar(!toggleNavBar);
    };

    const handleNavigation = (page) => {
        setSelected(page);
        setToggleNavBar(false);
        navigate(`/${page}`);
    };

    const getClassName = (page) =>
        `navbar-selection-inner ${selected === page ? 'navbar-selection-inner-background' : ''}`;

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (navRef.current && !navRef.current.contains(event.target)) {
                setToggleNavBar(false);
            }
        };

        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <div className='navBar-parent'>
            <div className='wrapper'>
                <nav className={`navBar ${toggleNavBar ? "active" : ""}`} ref={navRef}>
                    <div className="navbar-brand" onClick={() => handleNavigation('')}>
                        <Logo />
                    </div>
                    <div className='navHeader'>
                        <div className="navbar-links">
                            <a onClick={() => handleNavigation('product-page')}>PRODUCTS</a>
                            <a onClick={() => handleNavigation('development-page')}>FAZAL GUIDE</a>
                            <a onClick={() => handleNavigation('development-page')}>CERTIFICATIONS</a>
                            <a onClick={() => handleNavigation('aboutUs')}>ABOUT US</a>
                            <a onClick={() => handleNavigation('contactUs')}>CONTACT US</a>
                        </div>
                    </div>

                    <div className="mobile-icon" onClick={handleToggleNavBar}>
                        <NavBarIcon color={"#00000"} />
                    </div>
                    {!toggleNavBar && (
                        <div className={isSmallScreen && !toggleNavBar ? "bottom-nav" : "navbar-selection"}>
                            <div className='flex-center'>
                                {isSmallScreen && !toggleNavBar && (
                                    <div className="glee-logo-container">
                                        <GleeLogo />
                                    </div>
                                )}
                                <div className={getClassName('glee')} onClick={() => handleNavigation('glee')} style={{ padding: toggleNavBar ? "10px " : "" }}>
                                    <Typography fontSize={"16px"} fontWeight={700} sx={{ textAlign: "center" }}>
                                        Glee
                                    </Typography>
                                </div>
                            </div>
                            <div className='flex-center'>
                                {isSmallScreen && !toggleNavBar && (
                                    <div className="nongye-logo-container">
                                        <NongyeLogoGreen />
                                    </div>
                                )}
                                <div className={getClassName('nongye')} onClick={() => handleNavigation('nongye')}>
                                    <Typography fontSize={"16px"} fontWeight={700} sx={{ textAlign: "center" }}>
                                        Nongye
                                    </Typography>
                                </div>
                            </div>
                            <div className='flex-center'>
                                {isSmallScreen && !toggleNavBar && (
                                    <div className="cqi-logo-container">
                                        <CQILogoGreen />
                                    </div>
                                )}
                                <div className={getClassName('cqi')} onClick={() => handleNavigation('cqi')}>
                                    <Typography fontSize={"16px"} fontWeight={700} sx={{ textAlign: "center" }}>
                                        CQI
                                    </Typography>
                                </div>
                            </div>
                        </div>
                    )}
                </nav>
            </div>
        </div>
    );
}

export default Navbar;
