import React from 'react'
import "./index.css"
import Excellence from '../../assets/svg/excellence'
import LatestTech from '../../assets/svg/latestTech'
import Customer from '../../assets/svg/customer'
import Charges from '../../assets/svg/charges'
import FastTech from '../../assets/svg/fast'
import FreeConsultation from "../../assets/svg/freeConsultation"
const BannerBottomBar = () => {
  return (
    <div className='bannerBottomWrapper'>
  <div className='bannerBottom'>
      <Excellence/>
      <LatestTech />
      <Customer />
      <FastTech />
      <Charges />
      <FreeConsultation />
    </div>
    </div>
  
  )
}

export default BannerBottomBar