import React from 'react'

const Review = ({key}) => {
  return (
    <div key={key}>

<svg id="filled-star" xmlns="http://www.w3.org/2000/svg" width="14.359" height="14.359" viewBox="0 0 14.359 14.359">
  <path id="Path_33" data-name="Path 33" d="M0,0H14.359V14.359H0Z" fill="none"/>
  <path id="Path_34" data-name="Path 34" d="M0,0H14.359V14.359H0Z" fill="none"/>
  <path id="Path_35" data-name="Path 35" d="M7.983,11.136l3.7,2.232L10.7,9.161l3.267-2.83-4.3-.365L7.983,2,6.3,5.967,2,6.332l3.267,2.83-.981,4.206Z" transform="translate(-0.803 -0.803)"/>
</svg>

    </div>

  )
}

export default Review