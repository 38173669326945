import React from 'react'

const DownloadIcon = () => {
  return (
    <svg id="UploadMajor" xmlns="http://www.w3.org/2000/svg" width="13" height="13.26" viewBox="0 0 13 13.26">
    <path id="Path_82025" data-name="Path 82025" d="M3.5,4.25a.75.75,0,1,1,1.5,0v.51a.75.75,0,0,0,.75.75h8.5A.75.75,0,0,0,15,4.76V4.25a.75.75,0,1,1,1.5,0v.51a2.25,2.25,0,0,1-2.25,2.25H5.75A2.25,2.25,0,0,1,3.5,4.76Z" transform="translate(-3.5 6.25)" fill="#009035"/>
    <path id="Path_82026" data-name="Path 82026" d="M9.25,7.511a.75.75,0,1,1,1.5,0V14.2l1.72-1.72a.75.75,0,1,1,1.06,1.06l-3,3a.75.75,0,0,1-1.06,0l-3-3a.75.75,0,1,1,1.06-1.06L9.25,14.2V7.511Z" transform="translate(-3.5 -6.761)" fill="#009035"/>
  </svg>
  
  )
}

export default DownloadIcon
