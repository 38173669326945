import React,{useState,useEffect}from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTools } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import ModalLocation from '../../components/modal';

const UnderDevelopment = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const location = useSelector((state) => state.location)
  
    // Use useEffect to set the modal state based on the location
    useEffect(() => {
      // If location is not empty, set isModalOpen to false, otherwise true
      setIsModalOpen(!location);
    }, [location]); 
    const handleCloseModal = () => {
      setIsModalOpen(false);
    };
  return (
    <div style={styles.container}>
      <FontAwesomeIcon icon={faTools} size="3x" style={styles.icon} />
      <h1 style={styles.heading}>Under Development</h1>
      <p style={styles.paragraph}>
        We're working hard to bring you a great experience. Stay tuned!
      </p>
      <ModalLocation isOpen={isModalOpen} onClose={handleCloseModal} />

    </div>
  );
};

// Styles
const styles = {
  container: {
    textAlign: 'center',
    marginTop: '50px',
  },
  icon: {
    marginBottom: '20px',
  },
  heading: {
    color: '#2c3e50',
  },
  paragraph: {
    color: '#34495e',
  },
};

export default UnderDevelopment;
