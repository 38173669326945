import React from 'react'
import Review from '../../assets/svg/review'
import {  Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import "./index.css"
import ReqButton from '../button/reqButton';
const ProductCart = ({type,productData}) => {
    const navigate = useNavigate();
    const handleNavigation = () => {
        navigate(`/product`);
      };
      const reviewstar = () => {
        let reviews = [];
        for (let i = 0; i < productData.review; i++) {
          reviews.push(<Review key={i} />);
        }
        return reviews;
      };
    const className=type==="al-fazal" ? "product-Cart" :type==="Nongye" ?"product-Cart": "product-Cart-Glee"

    return (
        <div className={className}>
            <div className='inner-section'>
            <div className='product-image'>
                <img src={productData.image} />
            </div>
            <div className='product-review'>
                <div className='product-review-star'>
                {
                    reviewstar()
                }
                
                </div>
                <div className='product-review-customerReview'>
                   <Typography color={"#009035"} fontSize={"12px"} fontWeight={600}>
                  {productData.reviewText}
                   </Typography>
                </div>
            </div>
            <div className='product-text'>
                <Typography color={"#000000"} fontSize={"12px"} fontWeight={600}>
                {productData.productText}
             </Typography>           
            <div className='product-text-button'>
<ReqButton text={"See Details →"} color={"#FFFFFF"} className={"product-button-section"} onClick={() => handleNavigation()}/>
            </div>
            </div>
            </div>
         
        </div>
    )
}

export default ProductCart