import React, { useState } from 'react';
import "../navBar/index.css";
import { Typography } from '@mui/material';
import AboutUSCompany from '../aboutUsCompany';
import "./index.css"
const CompanySection = () => {
  const companies = [
    { name: 'Glee', displayName: 'About Glee' },
    { name: 'Nongye', displayName: 'About Nongye' },
    { name: 'CQI', displayName: 'About CQI' },
  ];

  // Initialize the selected state
  const [selected, setSelected] = useState("Glee");

  // Click handler to update the selected state
  const handleCompanyClick = (companyName) => {
    setSelected(companyName);
  };

  return (
    <div className='company-aboutus-parent'>
     <div className="navbar-selection">
      {companies.map((company) => {
        const className = selected === company.name 
          ? "navbar-selection-inner-background" 
          : "navbar-selection-inner";
        const fontColor = selected === company.name 
          ? "#FFFFFF" 
          : "#000";

        return (
          <>
     <div key={company.name} className={className} onClick={() => handleCompanyClick(company.name)}>
            <Typography fontSize={"16px"} fontWeight={700} color={fontColor} sx={{ textAlign: "center" }}>
              {company.displayName}
            </Typography>
          </div>

          </>
     
        );
      })}
      
    </div>
{ selected &&    <AboutUSCompany name={selected} />
}
    </div>
   

  );
}

export default CompanySection;
