import React from 'react'
import img from "../../assets/images/banner-image-glee.png"
import star from "../../assets/images/reviews-5-star.png"

import glessImg from "../../assets/images/glee-logo.png"
import "./index.css"
import {  Typography } from '@mui/material';
import ReqButton from "../../components/button/reqButton"
import ChatButton from '../../components/button/chatButton';
import BannerBottomBar from '../../components/bannerBottomBar';
import ProProduct from '../../components/proPrduct';
import ProductCart from '../../components/productCart';
import AboutSection from '../../components/aboutSection';
import MessageSection from '../../components/messageSection';
import companyImage from "../../assets/images/bg-assets.png"
import Certification from '../../components/certificationSection';
import ReviewSection from '../../components/reviewSection';
import QuestionSection from '../../components/questionSection';
import ImageSection from '../../components/imageSection/imageSection';
import FeedBack from '../../components/feedBackSection';
import Consultation from '../../components/consSection';
import LocationSection from '../../components/locationSection';
import CompanySection from '../../components/companySelction'
import AboutUSCompany from '../../components/aboutUsCompany'
import VideoSection from '../../components/videoSection'
import Client from '../../components/certificationSection/clients'
const GleeHomePage = () => {
  return (
    <div>
     <div className='banner-image-parent-glee'>
       
         <div>
         <div className='banner-image-glee'>
         <img src={glessImg}/>
         </div>
         <div className='banner-data-star-glee'>
             <img src={star}/>
             <Typography fontSize={"14px"} fontWeight={600} color={"#FFFFFF"} sx={{marginLeft:"5px"}}>
             ( Top-Notch Products With 10,000+ Happy And Satisfactory Farmers )
             </Typography>
             </div>
             <div className='banner-data-heading-glee'>
            <Typography fontSize={"55px"} fontWeight={400} color={"#FFFFFF"} sx={{letterSpacing: "1.1px"}}>
            GLEE PROVIDES THE BEST SOLUTIONS FOR YOUR AGRICULTURE             </Typography>
            </div>
         </div>
      
         </div>
    
         <BannerBottomBar />
         <div className='glee-company-client'>
          <Client/>
         </div>
         {/* <div className='glee-company-center'>
         <CompanySection selected="Glee" />
          </div> */}
       <AboutUSCompany name={"Glee"}/>
         <ProProduct type={"glee"} />
        
         <ReviewSection type={"glee"}/>
         <VideoSection />
         <QuestionSection type={"glee"}/>
         <ImageSection />
         <div className='banner-Bottom-below'>
         <BannerBottomBar />
         </div>
        <FeedBack />
      
        <Consultation />
       <LocationSection />
       <BannerBottomBar />
    </div>
  )
}

export default GleeHomePage