import React,{useState,useEffect}from 'react'
import {  Typography } from '@mui/material';
import "./index.css"
import FeedBack from '../../components/feedBackSection';
import ReqButton from '../../components/button/reqButton';
import { useSelector } from 'react-redux';
import ModalLocation from '../../components/modal';

const ContactUsPage = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const location = useSelector((state) => state.location)
  
    // Use useEffect to set the modal state based on the location
    useEffect(() => {
      // If location is not empty, set isModalOpen to false, otherwise true
      setIsModalOpen(!location);
    }, [location]); 
    const handleCloseModal = () => {
      setIsModalOpen(false);
    };
  return (
    <div className='wrapper' >
        <div className='contactUs-page-parent'>
        <Typography fontSize={"55px"} fontWeight={400} color={"#000"} sx={{ letterSpacing: "1.1px" }}>
     
     Conatct Us
      </Typography>

      <div className='text-contact-parent'>
        <div>

      <div className='contactUs-text'>
      <Typography fontSize={"20px"} fontWeight={700} color={"#000"} >
     
     Email:
      </Typography>
      <Typography fontSize={"15px"} fontWeight={400} color={"#000"} >
     
test1234@gmail.com
      </Typography>
      </div>
      <div className='contactUs-text'>
      <Typography fontSize={"20px"} fontWeight={700} color={"#000"} >
     
     Phone:
      </Typography>
      <Typography fontSize={"15px"} fontWeight={400} color={"#000"} >
     
+92-316-908976958
      </Typography>
      </div>
        </div>
        <div>
        <div className='feedback-section-cotact'>
          
        
    <form className='formContainer'>
        <div className='rowInputField'> 
        <input type="text" id="fname" name="fname" placeholder="Name" className='input-field'/>
        <input type="text" id="email" name="email" placeholder="Email" className='input-field'/>

        </div>
        <div className='rowInputField'> 
        <input type="text" id="phoneNumber" name="phoneNumber" placeholder="PhoneNumber" className='input-field'/>
        <input type="text" id="city" name="city" placeholder="City" className='input-field'  />
        </div>
        <textarea id="Message" name="Message" rows="12" cols="100" placeholder="Message"  className='area-field'></textarea>
    </form>
    <div className='parent-feedback-section'>
        <div className='question-product-button-about'> 
         <ReqButton text={"Reach Out To Us Now!"} color={"#000000"} className={"product-button"}/>
         </div>
         
        </div>
    </div>
  
        </div>
      </div>
   
        </div>
        <ModalLocation isOpen={isModalOpen} onClose={handleCloseModal} />

    </div>
  )
}

export default ContactUsPage