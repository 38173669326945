import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AppBar from "./components/appBar";
import FooterSection from './components/footerSection';
import Navbar from './components/navBar';
import CQIHomePage from './pages/cqiHomePage';
import FazalHomePage from './pages/fazalHomePage';
import GleeHomePage from './pages/gleeHomePage';
import NongyePage from './pages/nongyePage';
import ProductPage from './pages/productPage';
import ScrollToTop from './components/scroll';
import { store } from '../src/redux/store';
import { Provider } from 'react-redux';
import "./App.css";
import ProductPageNav from './pages/products';
import UnderDevelopment from './pages/constructionPage';
import AboutUS from './pages/aboutusPage';
import ContactUsPage from './pages/contactUsPage';

function App() {
  const [userLocation, setUserLocation] = useState(null);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation({ latitude, longitude });
        },
        (error) => {
          console.error("Error getting user location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <ScrollToTop />
        <div className="App">
          <AppBar userLocation={userLocation} />
          <Navbar />
          <Routes>
            <Route index element={<FazalHomePage />} />
            <Route path="CQI" element={<CQIHomePage />} />
            <Route path="product" element={<ProductPage />} />
            <Route path="glee" element={<GleeHomePage />} />
            <Route path="nongye" element={<NongyePage />} />
            <Route path="product-page" element={<ProductPageNav />} />
            <Route path="development-page" element={<UnderDevelopment />} />
            <Route path="aboutUs" element={<AboutUS />} />
            <Route path="contactUs" element={<ContactUsPage />} />
          </Routes>
          <FooterSection />
        </div>
      </Router>
    </Provider>
  );
}

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

export default App;
