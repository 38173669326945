import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import LocationSelector from '../locationSelector';
import "./index.css";
import { useSelector, useDispatch } from 'react-redux'
import { saveLocation } from '../../redux/features/dealers/dealersSlice'

const ModalLocation = ({ isOpen, onClose }) => {
  const dispatch = useDispatch()

  const [selectedLocation, setSelectedLocation] = useState(null);
 
  const [showError, setShowError] = useState(false);



  const handleLocationChange = (selectedOption) => {
    setSelectedLocation(selectedOption.value);
    setShowError(false); // Reset error state if a location is selected
  };

  const handleClose = () => {
    if (!selectedLocation) {
      setShowError(true); // Show error if no location is selected
    } else {
      dispatch(saveLocation(selectedLocation));

      onClose(); // Proceed to close the modal if a location is selected
    }
  };

  if (!isOpen) return null;
  return (
    <div className="modal-overlay">
      <div className="modal">
        <div className="modal-header">
          <Typography fontSize={"32px"} fontWeight={700}>Please choose a location below:</Typography>
        </div>
        <div className="modal-body">
          {showError && <Typography color={"red"}>You must choose your location before entering the site.</Typography>}
          <div className="modal-actions">
            <LocationSelector onLocationChange={handleLocationChange} />
          </div>
        </div>
        <div className="modal-footer">
          <button onClick={handleClose} className="modal-button">CONTINUE TO WEBSITE</button>
        </div>
      </div>
    </div>
  );
};

export default ModalLocation;
