import React from 'react'
import "./index.css"
import {  Typography } from '@mui/material';
import MapComponent from '../mapComponents';
// import Map from 'react-map-gl';

const LocationSection = () => {
  return (
    <div className='location-section'> 
      <Typography fontSize={"35px"} fontWeight={700} color={"#00000"}>
      Our Store Locations
      </Typography>
      {/* <Map
    mapLib={import('mapbox-gl')}
    initialViewState={{
      longitude: -100,
      latitude: 40,
      zoom: 3.5
    }}
    style={{width: 600, height: 400}}
    mapStyle="mapbox://styles/mapbox/streets-v9"
  /> */}
    </div>
  )
}

export default LocationSection