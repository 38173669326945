import React from 'react'
import "./index.css"
import CeoImage from '../../assets/images/ceo-image.png';
import {  Typography } from '@mui/material';

const MessageSection = () => {
  return (
    <div className='wrapper-message'>
       <div className='messgaeSection'>
    <div className='messgaeSection-text' >
    <Typography fontSize={"35px"} fontWeight={700} color={"#000000"}>
        Message From CEO
      </Typography>
      <div className='messgaeSection-para'>
        <Typography fontSize={"18px"} color={"#000000"}>
          We continue our journey towards <strong>innovation and sustainability</strong> in agriculture. Our commitment to developing cutting-edge solutions for farmers around the world has not only strengthened our position in the market but has also contributed to the greater cause of sustainable agriculture. Our research and development teams have been hard at work, and I am thrilled to announce that we have successfully launched our <strong>latest generation of precision sprayers</strong> . These are not only <strong>more efficient</strong> but also <strong>environmentally friendly, reducing waste </strong>and <strong>minimizing the impact on ecosystems.</strong>
        </Typography>
      </div>
    </div> 
      <div className='image-ceo'>
      <img src={CeoImage} />
      </div>
    </div>
    </div>
   
  )
}

export default MessageSection