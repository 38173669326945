import React from 'react'
import "./index.css"
import {  Typography } from '@mui/material';

const VideoCard = ({image,headingText,paraText}) => {
  return (
    <div className="video-card">
<div className='video-card-image'>
<img src={image} alt={"image"}/>
</div>
       
        <Typography fontWeight={700} fontSize={"18px"} mt={"22px"} sx={{display:"flex",flexWrap:"wrap",maxWidth:"276px"}}>
          {headingText}
        </Typography>
        <Typography fontWeight={400} fontSize={"14px"} mt={"12px"} sx={{display:"flex",flexWrap:"wrap",maxWidth:"276px"}}>
          {paraText}
        </Typography>
    </div>
  )
}

export default VideoCard