import React from 'react'
import "./index.css"
import "../navBar/index.css"
import { Typography } from '@mui/material';
import MainLogo from '../../assets/images/logo-symbol.png';
import star from "../../assets/images/reviews-5-star.png"
import ProductCart from '../productCart';
import ReqButton from '../button/reqButton';
import NextIcon from "../../assets/images/next-icon.png"
import PreviousIcon from "../../assets/images/previous-icon.png"
import GleeLogo from '../../assets/svg/gleeLogo';
import NongyeLogoGreen from '../../assets/svg/nongyeLogoGreen';
import CQILogoMain from '../../assets/svg/CQILogoMain';
import CQILogoGreen from '../../assets/svg/CQILogoGreen';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import productImage from "../../assets/images/bottle-class.png"
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';

const ProProduct = ({type}) => {
   const className=type==="al-fazal" ? "product" : type==="Nongye" ? "product":"gle-product"
   const name=type==="al-fazal" ? "Fazal Group CQI" :type==="Nongye" ? "Nongye":type==="CQI" ? "CQI" :"Glee"
   const buttonText=type==="al-fazal" ? "Learn More About Cotton Quality Industry →" :type==="CQI" ? "Learn More About CQI Industry →":type==="Nongye" ? "Learn More About Nongye Industry →":"Learn More About Glee →"
   const buttonClassName=type==="al-fazal" ? "product-button" :type==="Nongye" ? "product-button":"glee-button"
   const backgroundColor=type==="al-fazal" ? "#F4F4F4" : type==="Nongye" ? "":"#fff"
   function CustomNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "none"}}
        onClick={onClick}
      >
        <img src={NextIcon} alt="Next" />
      </div>
    );
  }
  
  function CustomPrevArrow(props) {
    const { className, style, onClick } = props;
   
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "none",marginRight:"20px"}}
        onClick={onClick}
      >
        <img src={PreviousIcon} alt="Previous" />
      </div>
    );
  }
   const Products =[{
    image:require("../../assets/images/bottle-class.png"),
    reviewText:"( Read 54 Reviews )",
    productText:" Finest 10% SL Pack",
    review:4
   },
   {
    image:require("../../assets/images/bottle-class.png"),
    reviewText:"( Read 34 Reviews )",
    productText:" Finest 5% SL Pack",
    review:4
   },
   {
    image:require("../../assets/images/bottle-class.png"),
    reviewText:"( Read 24 Reviews )",
    productText:" Finest 15% SL Pack",
    review:5
   },
   {
    image:require("../../assets/images/bottle-class.png"),
    reviewText:"( Read 4 Reviews )",
    productText:" Finest 25% SL Pack",
    review:3
   },
   {
    image:require("../../assets/images/bottle-class.png"),
    reviewText:"( Read 4 Reviews )",
    productText:" Finest 25% SL Pack",
    review:3
   },
   {
    image:require("../../assets/images/bottle-class.png"),
    reviewText:"( Read 4 Reviews )",
    productText:" Finest 25% SL Pack",
    review:3
   },
   {
    image:require("../../assets/images/bottle-class.png"),
    reviewText:"( Read 4 Reviews )",
    productText:" Finest 25% SL Pack",
    review:3
   }
  ]
   const responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
      }
    };
    const settings = {
      dots: false,
      infinite: true, // Enable infinite looping
      speed: 3000, // Adjust the speed of the transition between slides
      slidesToShow: 4, // Show 5 slides at a time
      slidesToScroll: 1, // Scroll 1 slide at a time
      autoplay: true, // Enable autoplay to automatically cycle through slides
      autoplaySpeed: 0, // Set to 0 for continuous scrolling effect
      cssEase: "linear", // Use linear animation for smooth continuous scrolling
      pauseOnHover: true,
      nextArrow: <CustomNextArrow />,
      prevArrow: <CustomPrevArrow />, 
      
      responsive: [
        {
          breakpoint: 1440,
          settings: {
      
            dots: false,
            infinite: true, // Enable infinite looping
            speed: 3000, // Adjust the speed of the transition between slides
            slidesToShow: 3, // Show 5 slides at a time
            slidesToScroll: 1, // Scroll 1 slide at a time
            autoplay: true, // Enable autoplay to automatically cycle through slides
            autoplaySpeed: 0, // Set to 0 for continuous scrolling effect
            cssEase: "linear", // Use linear animation for smooth continuous scrolling
            pauseOnHover: true,

          }
        },
        {
          breakpoint: 1100,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
           
          }
        },
        {
          breakpoint: 750,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        },
      
      ]
    };
  
   return (

    <div style={{backgroundColor}}>
  <div className={className}>


<div className='product-container'> 
<div className={type==="al-fazal" ? 'main-logo': ''}>
            {type==="glee" ? <GleeLogo/> : type==="Nongye"?<NongyeLogoGreen/>:type==="CQI"?<CQILogoGreen/>:<CQILogoGreen/>}   
            </div>
         <div className='product-text'> 
            <Typography color={"#000000"} fontSize={"35px"} fontWeight={700}>
           
           {type==="al-fazal" ? "Premium Cotton Quality Products" : type==="Nongye" ?"Nongye Products":type==="CQI" ?"CQI Products":"Glee Products"}
           
            </Typography>
         </div>
         <div className='product-text-para'> 
            <Typography color={"#000000"} fontSize={"22px"} fontWeight={700}>
            At {name}, we understand that the key to superior cotton lies in effective pest management and disease prevention. Our specialized cotton sprays are tailored to safeguard your crop.
            </Typography>
         </div>
         <div  className='product-text-products'>
       
     <Slider {...settings}>
              {Products.map((productData, index) => (
                <div key={index}>
                  <ProductCart type={type} productData={productData} />
                </div>
              ))}
            </Slider>
         {/* <ProductCart type={type}/>
         <ProductCart type={type}/>
         <ProductCart type={type}/>
         <ProductCart type={type}/> */}
         {/* <div  className="image-arrow-next">
     <img src={NextIcon} alt='loading...'/>
     </div> */}
         </div>
         <div className='product-button-about'> 
         <ReqButton text={buttonText} color={"#000000"} className={buttonClassName}/>
         </div>
         <div className='product-overall-rating'>
         <img src={star}/>
             <Typography fontSize={"14px"} fontWeight={600} color={"#000000"} sx={{marginLeft:"5px"}}>
             ( 10,000+ Happy And Satisfactory Farmers )
             </Typography>
         </div>
   
</div>
 
     
    </div>
    </div>
  
    

  )
}

export default ProProduct