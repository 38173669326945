import React, { useState, useEffect } from 'react';
import img1 from "../../assets/images/image-01.png";
import img2 from "../../assets/images/image-02.png";
import img3 from "../../assets/images/image-03.png";
import img4 from "../../assets/images/image-04.png";
import img5 from "../../assets/images/image-05.png";
import companyImage from "../../assets/images/bg-assets.png";
import PreviousIcon from "../../assets/images/previous-icon.png";
import NextIcon from "../../assets/images/next-icon.png";
import "./index.css";

const images = [img1, img2, img3, img4, img5, img4, img3,img1, img2, img3, img4, img5, img4, img3]; // Assuming you have 7 images for demonstration

const ImageSection = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [displayImages, setDisplayImages] = useState(images.slice(0, 5));
  const [imagesToShow, setImagesToShow] = useState(5); // Default value

  // Function to determine how many images to show based on screen width
  const updateImagesToShow = () => {
    if (window.innerWidth < 650) {
      setImagesToShow(1);
    } else if (window.innerWidth < 750) {
      setImagesToShow(1);
    } else if (window.innerWidth < 1210) {
      setImagesToShow(3);
    } else if (window.innerWidth < 1550) {
      setImagesToShow(4);
    } else {
      setImagesToShow(5);
    }
  };
  useEffect(() => {
    updateImagesToShow();
    // Add event listener for window resize
    window.addEventListener('resize', updateImagesToShow);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener('resize', updateImagesToShow);
  }, []);

  useEffect(() => {
    const newImages = images.slice(currentIndex, currentIndex + imagesToShow);
    setDisplayImages(newImages);
  }, [currentIndex, imagesToShow]);
  // Show next set of images, minimum 5 if possible
  const handleNextClick = () => {
    const newIndex = currentIndex + 5; // Move forward by 5 images
    const newImages = images.slice(newIndex, Math.min(images.length, newIndex + 5));
    setDisplayImages(newImages);
    setCurrentIndex(newIndex);
  };

  // Show previous set of images, aim for 5
  const handlePreviousClick = () => {
    const newIndex = Math.max(0, currentIndex - 5); // Move back by 5 images, but not below 0
    const newImages = images.slice(Math.max(0, newIndex - 5), newIndex);
    setDisplayImages(images.slice(newIndex, newIndex + 5)); // Ensure to show 5 images
    setCurrentIndex(newIndex);
  };

  // Disable previous arrow if current index is 0 (initial state)
  const previousArrowDisabled = currentIndex === 0;
  // Disable next arrow if there are no more images to display beyond the current set
  const nextArrowDisabled = currentIndex + 5 >= images.length;

  return (
    <div>
      <div className='Image-Section'>
        {displayImages.map((image, index) => (
          <img key={index} src={image} alt='Display'/>
        ))}
      </div>
      <div className='company-image'>
        <img src={companyImage} alt='Company'/>
        <div className="image-arrow">
          {!previousArrowDisabled && <img src={PreviousIcon} alt='Previous' onClick={handlePreviousClick}/>}
          {!nextArrowDisabled && <img src={NextIcon} alt='Next' onClick={handleNextClick}/>}
        </div>
      </div>
    </div>
  );
}

export default ImageSection;
