import React from 'react'
import "./index.css"
import ReqButton from '../button/reqButton'
import MainLogo from '../../assets/images/logo-symbol.png';
import star from "../../assets/images/reviews-5-star.png";
import {  Typography } from '@mui/material';

const AboutSection = () => {
  return (
    <div className="about-section">


<div className='about-section-main-logo'>
               <img src={MainLogo}/>
            </div>
            <div className='about-section-product-text'> 
            <Typography color={"#000000"} fontSize={"35px"} fontWeight={700}>
            About Fazal Group Of Companies
            </Typography>
         </div>
         <div className='about-section-product-text-para'> 
            <Typography color={"#000000"} fontSize={"22px"} fontWeight={700}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur sagittis metus eget lorem pharetra mollis sit amet non nunc. Nullam eu metus quis quam iaculis fringilla. Ut semper massa nisi, eu feugiat neque semper in. Phasellus aliquet lorem vel vulputate finibus. Nullam efficitur consectetur consectetur. Etiam pellentesque ligula tellus, at placerat neque auctor in. Nulla et viverra sapien, vel sodales sapien.
            </Typography>
         </div>
         <div className='about-section-product-button-about'> 
         <ReqButton text={"Learn More About Us →"} color={"#000000"} className={"product-button"}/>
         <ReqButton text={"Call Us: (061) 457 900 110"} color={"#000000"} className={"req-button"}/>
         </div>
         <div className='product-overall-rating'>
         <img src={star}/>
             <Typography fontSize={"14px"} fontWeight={600} color={"#000000"} sx={{marginLeft:"5px"}}>
             ( 10,000+ Happy And Satisfactory Farmers )
             </Typography>
         </div>
    </div>
  )
}

export default AboutSection