import React from 'react';
import Select from 'react-select';
import "./index.css"
const locationOptions = [
  { value: 'Lahore', label: "Lahore"  },
  { value: 'Islamabad', label: 'Islamabad' },
  { value: 'Bahawalpur', label: 'Bahawalpur' },

  // ... you can add as many locations as you have
];

const LocationSelector = ({ onLocationChange }) => {

  const handleLocationChange = (selectedOption) => {
    console.log(`Option selected:`, selectedOption);
    onLocationChange(selectedOption); // Call the callback prop with the selected option
  };

  return (
    <div className="location-selector">
      <Select
        options={locationOptions}
        onChange={handleLocationChange}
        className="basic-single"
        classNamePrefix="select"
        placeholder="Choose Your Location"
        isClearable
        isSearchable
      />
    </div>
  );
};

export default LocationSelector;