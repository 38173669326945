import React from 'react';
import Slider from "react-slick";
import Typography from '@mui/material/Typography';
import './index.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Assuming your clients' logos are named in a sequential order
const clients = new Array(27).fill(null).map((_, index) => require(`../../assets/images/client${index + 1}.png`));
const settings = {
  dots: false,
  infinite: true,
  slidesToShow: 10,
  slidesToScroll: 1,
  autoplay: true,
  speed: 2000,
  autoplaySpeed: 2000,
  cssEase: "linear",
  arrows: false
};

const CertificationSlider = ({ images }) => (
  <Slider {...settings}>
    {images.map((src, index) => (
      <div key={index} className="container"> 
        <img src={src} alt={`Client ${index + 1}`} />
      </div>
    ))}
  </Slider>
);

const Certification = ({Certification}) => (
  <div className='certification-section'>
    <div className='certification-section-main-logo'>
      <img src={require('../../assets/images/logo-symbol.png')} alt="Main Logo" />
    </div>
    <div className='about-section-product-text'> 
      <Typography color="#000000" fontSize="35px" fontWeight={700}>
        Our Certifications
      </Typography>
    </div>
    <div className="mainContainer-client">
      <CertificationSlider images={clients} />
    </div>
  </div>
);

export default Certification;
