import React from 'react'

const MiniProduct = () => {
  return (
    <svg id="image-02" xmlns="http://www.w3.org/2000/svg" width="89" height="90" viewBox="0 0 89 90">
    <rect id="IMG_1207" width="89" height="90" rx="10" transform="translate(0 0)" fill="#e0e0e0"/>
  </svg>  
  )
}

export default MiniProduct
