import React,{useEffect,useState} from 'react'
import ProProduct from '../../components/proPrduct'
import { useSelector } from 'react-redux';
import ModalLocation from '../../components/modal';

const ProductPageNav = () => {
    const location = useSelector((state) => state.location); // Adjust based on your state structure
  const [isModalOpen, setIsModalOpen] = useState(!location);

  useEffect(() => {
    // If there is no location set, ensure the modal is open
    setIsModalOpen(!location);
  }, [location]);
  return (
    <div>   <ProProduct type={"al-fazal"} />
    <ProProduct  type={"glee"}/>
    <ProProduct type={"Nongye"}/>
    
    {isModalOpen && <ModalLocation isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />}

    </div>
  )
}

export default ProductPageNav