import React from 'react'
import "./index.css"
import map from "../../assets/images/map.png"
import star from "../../assets/images/reviews-5-star.png"
import {  Typography } from '@mui/material';
import Logo from '../../assets/svg/logo';


const FooterSection = () => {
    return (
        <>
        
        <div className='footer-section'>
            <div className='footer-section-warpper'>
             <div className='footer-parent'>

            <div className="footer-brand">
                <Logo />
                <Typography fontSize={"14px"} fontWeight={"500"} sx={{ textAlign: "center", maxWidth: "263px" }}>
                    We developing cutting-edge solutions for farmers around the world has not only strengthened our position in the market but has also contributed to the greater cause of sustainable agriculture.
                </Typography>
            </div>
            <div className='footer-mobile'>

            <div className='footer-pages'>
                <Typography fontSize={"18px"} fontWeight={"700"} color={"#000000"}>
                    Our Company
                </Typography>
                <div className='footer-pages-list'>
                <Typography fontSize={"14px"} fontWeight={"500"} color={"#000000"}>
            Products
                </Typography>
                <Typography fontSize={"14px"} fontWeight={"500"} color={"#000000"}>
            Crops
                </Typography>
                <Typography fontSize={"14px"} fontWeight={"500"} color={"#000000"}>
            Certifications
                </Typography>
                <Typography fontSize={"14px"} fontWeight={"500"} color={"#000000"}>
            About Us
                </Typography>
                <Typography fontSize={"14px"} fontWeight={"500"} color={"#000000"}>
            Cantact Us
                </Typography>
                </div>
            </div>
            <div className='footer-pages-products'>
                <Typography fontSize={"18px"} fontWeight={"700"} color={"#000000"}>
                Our Products
                </Typography>
                <div className='footer-pages-list'>
                <Typography fontSize={"14px"} fontWeight={"500"} color={"#000000"}>
                Pests Spray
                </Typography>
                <Typography fontSize={"14px"} fontWeight={"500"} color={"#000000"}>
                Fertilizers
                </Typography>
                <Typography fontSize={"14px"} fontWeight={"500"} color={"#000000"}>
                Pesticides
                </Typography>
                <Typography fontSize={"14px"} fontWeight={"500"} color={"#000000"}>
                Herbicides
                </Typography>
                <Typography fontSize={"14px"} fontWeight={"500"} color={"#000000"}>
                Seeds
                </Typography>
                <Typography fontSize={"14px"} fontWeight={"500"} color={"#000000"}>
                Livestock Feed
                </Typography>
                <Typography fontSize={"14px"} fontWeight={"500"} color={"#000000"}>
                Soil Conditioners
                </Typography>
                </div>
            </div>
            </div>
            <div className='footer-map'>
            <Typography fontSize={"18px"} fontWeight={"700"} color={"#000000"}>
            Map
                </Typography >

                <img src={map} alt="loading..."/>
                <Typography fontSize={"14px"} fontWeight={"500"} color={"#000000"}>
                Address: 59/3 Abdali Rd, High Court Staff Colony, Multan, PunjabPhone: (061) 457 900 110
                </Typography>
            </div>
             </div>
         <div className='customer-section-review'>
         <img src={star}/>
             <Typography fontSize={"14px"} fontWeight={600} color={"#000000"} sx={{marginLeft:"5px"}}>
             ( 10,000+ Happy And Satisfactory Farmers )
             </Typography>
         </div>
            </div>

        </div>
         <div className='copyRight-section'>
            <div>
                  
            </div>
            <Typography color= "#FFFFFF" font={"15px"} fontWeight={400}>
            © 2023 © FazalGroup. All rights reserved
            </Typography>
            <Typography color= "#FFFFFF" font={"15px"} fontWeight={600}>
            Privacy Policy - Terms of Service
            </Typography>
         </div>
         </>
    )
}

export default FooterSection