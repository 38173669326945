import React,{useState} from 'react'
import "./index.css"
import {  Typography } from '@mui/material';
import QuestionCard from '../questionCard';
import star from "../../assets/images/reviews-5-star.png"
import ReqButton from '../button/reqButton';
import GleeLogo from '../../assets/svg/gleeLogo';
import mainLogo from "../../assets/images/logo-symbol.png"
import NongyeLogo from '../../assets/svg/nongyeLogo';
import NongyeLogoGreen from '../../assets/svg/nongyeLogoGreen';
import CQILogoGreen from '../../assets/svg/CQILogoGreen';
const QuestionSection = ({type}) => {
  const [openQuestionIndex, setOpenQuestionIndex] = useState(null);

  const handleQuestionClick = (index) => {
    // Toggle between open and close state
    setOpenQuestionIndex(openQuestionIndex === index ? null : index);
  };

  const questions = [
    "Which pests and diseases do your sprays target, and how effective are they?",
    "What are the key ingredients in your sprays, and are they eco-friendly?",
  "Are your sprays suitable for diverse cotton-growing conditions?",
  "Can you share success stories from farmers using your products?",
  "How do you ensure product quality and consistency?"
  ];
  const answers = [
    "Our specialized cotton sprays target a range of common cotton pests and diseases, including aphids, bollworms, and fungal infections. These sprays have been formulated to be highly effective in preventing and managing these issues, helping to safeguard your cotton crop and maximize its yield and quality.",
    "Our specialized cotton sprays target a range of common cotton pests and diseases, including aphids, bollworms, and fungal infections. These sprays have been formulated to be highly effective in preventing and managing these issues, helping to safeguard your cotton crop and maximize its yield and quality.",
    "Our specialized cotton sprays target a range of common cotton pests and diseases, including aphids, bollworms, and fungal infections. These sprays have been formulated to be highly effective in preventing and managing these issues, helping to safeguard your cotton crop and maximize its yield and quality.",
    "Our specialized cotton sprays target a range of common cotton pests and diseases, including aphids, bollworms, and fungal infections. These sprays have been formulated to be highly effective in preventing and managing these issues, helping to safeguard your cotton crop and maximize its yield and quality.",
    "Our specialized cotton sprays target a range of common cotton pests and diseases, including aphids, bollworms, and fungal infections. These sprays have been formulated to be highly effective in preventing and managing these issues, helping to safeguard your cotton crop and maximize its yield and quality.",
  ];

  return (
    <div className='question-section'>
        <div className='section-img'>
        {type==="glee" ? <GleeLogo/> :type ==="Nongye" ?<NongyeLogoGreen/>:type==="CQI"?<CQILogoGreen/>:<img src={mainLogo}/>}   
        <Typography color={"#000"} fontSize={"35px"} fontWeight={700} sx={{display:"flex",justifyContent:"center"}}>
        You Got Questions? We Got Your Answers!
        </Typography>
    </div>
      
        <div className='question-section-card'>
        {questions.map((text, index) => (
          <div key={index}>
            <QuestionCard
              text={text}
              onClick={() => handleQuestionClick(index)}
              isOpen={openQuestionIndex === index}
            />
            {openQuestionIndex === index && (
              <div className='question-card-answer'>
   <Typography fontSize={"18px"} color={"#000"} style={{ marginTop: '10px' }}>
                {answers[index]}
              </Typography>
              </div>
           
            )}
          </div>
        ))}
      </div>
        <div>
        <div className='question-product-button-about-parent'>
        <div className='question-product-button-about'> 
         <ReqButton text={"Ask Your Question →"} color={"#000000"} className={"product-button"}/>
         </div>
         <div className='question-product-overall-rating'>
         <img src={star}/>
             <Typography fontSize={"14px"} fontWeight={600} color={"#000000"} sx={{marginLeft:"5px"}}>
             ( 10,000+ Happy And Satisfactory Farmers )
             </Typography>
         </div>
        </div>
        </div>
     
      
    </div>
  )
}

export default QuestionSection   