import React from 'react'
import "./index.css"
import {  Typography } from '@mui/material';

const  ReqButton= ({text,color,className,onClick}) => {
  return (
    <>
    <div className={className} onClick={onClick}>
      <Typography color={color} fontWeight={700} sx={{textAlign:"center"}} fontSize={"16px"}>
    {text}
      </Typography>
    </div>
    
    </>



  )
}

export default ReqButton