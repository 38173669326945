import React from 'react'

const GleeLogo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="58.246" height="48.54" viewBox="0 0 58.246 48.54">
  <g id="logo-symbol" transform="translate(-694.002 -4559.929)">
    <path id="Path_81923" data-name="Path 81923" d="M47.809,30.08C46.7,11.739,25.247,15.463,17.946,7.242,21.592,28.08,30.507,37.111,45.208,33.178q-8.294-5.45-9.951-8.02c4.184,1.64,8.368,5.051,12.552,4.922" transform="translate(676.056 4574.393)" fill="#009035" fill-rule="evenodd"/>
    <path id="Path_81924" data-name="Path 81924" d="M31.1,46.26C28,34.122,26,22.619,31.642,15.578,34.29,11.891,41.1,6.953,54.637,0c-2.6,3.852-3.811,10.252-3.333,19.727C52.934,35.07,46.929,41.554,36.336,48.3c-2.259-3.708-2.776-7.88-2.313-12.314A41.268,41.268,0,0,0,31.1,46.26Z" transform="translate(697.612 4559.929)" fill="#009035" fill-rule="evenodd"/>
  </g>
</svg>

  )
}

export default GleeLogo
