import React from 'react'
import img from "../../assets/images/bannerCQI@2x.png"
import star from "../../assets/images/reviews-5-star.png"

import "./index.css"
import {  Typography } from '@mui/material';
import BannerBottomBar from '../../components/bannerBottomBar';
import ProProduct from '../../components/proPrduct';
import ReviewSection from '../../components/reviewSection';
import QuestionSection from '../../components/questionSection';
import ImageSection from '../../components/imageSection/imageSection';
import FeedBack from '../../components/feedBackSection';
import Consultation from '../../components/consSection';
import LocationSection from '../../components/locationSection';
import CompanySection from '../../components/companySelction'
import AboutUSCompany from '../../components/aboutUsCompany'
import VideoSection from '../../components/videoSection'
import Client from '../../components/certificationSection/clients'
import CQILogoMain from '../../assets/images/logo-symbol-cqi.png'
const CQIHomePage = () => {
  return (
    <div >
     <div className='banner-image-parent-cqi'>
         <div className='banner-image-CQI'>
         <img src={CQILogoMain} alt='cotton-product' />
         </div>
         <div className='wrapper'>
         <div className='banner-data-star-CQI'>
             <img src={star}/>
             <Typography fontSize={"14px"} fontWeight={600} color={"#FFFFFF"} sx={{marginLeft:"5px"}}>
             ( Top-Notch Products With 10,000+ Happy And Satisfactory Farmers )
             </Typography>
             </div>
         </div>
       
             <div className='banner-data-heading-CQI'>
            <Typography fontSize={"55px"} fontWeight={400} color={"#FFFFFF"} sx={{letterSpacing: "1.1px"}}>
            COTTON QUALITY INDUSTRY PROVIDES THE BEST SOLUTIONS FOR YOUR AGRICULTURE      </Typography>
            </div>
         </div>
    
         <BannerBottomBar />
         <div className='CQI-company-client'>
          <Client/>
         </div>
         {/* <div className='CQI-company-center'>
         <CompanySection selected="CQI" />
          </div> */}
       <AboutUSCompany  name={"CQI"}/>
         <ProProduct type={"CQI"} />
        
         <ReviewSection type={"CQI"}/>
         <VideoSection />
         <QuestionSection type={"CQI"}/>
         <ImageSection />
         <div className='banner-Bottom-below'>
         <BannerBottomBar />
         </div>
        <FeedBack />
      
        <Consultation />
       <LocationSection />
       <BannerBottomBar />
    </div>
  )
}

export default CQIHomePage