import React from 'react'

import mainLogo from "../../assets/images/logo-symbol.png"
import {  Typography } from '@mui/material'
import "./index.css"
import ReviewCard from '../reviewCard'
import GleeLogo from '../../assets/svg/gleeLogo'
import NongyeLogo from '../../assets/svg/nongyeLogo'
import NongyeLogoGreen from '../../assets/svg/nongyeLogoGreen'
import CQILogoGreen from '../../assets/svg/CQILogoGreen'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "../reviewSection/index.css"
import Review from '../../assets/images/reviews-5-star.png'

const ReviewSection = ({type}) => {
    const reviews = [
        { name: 'Sarah D.', review: "Glee Products cotton sprays have been a game-changer for my cotton farm. I've struggled with pest infestations in the past, but these sprays have proven to be highly effective. My crop has never looked healthier, and my yield has gone up significantly. Plus, their customer support is top-notch!"},
        { name: 'Sarah D.', review: "Glee Products cotton sprays have been a game-changer for my cotton farm. I've struggled with pest infestations in the past, but these sprays have proven to be highly effective. My crop has never looked healthier, and my yield has gone up significantly. Plus, their customer support is top-notch!"},
        { name: 'Sarah D.', review: "Glee Products cotton sprays have been a game-changer for my cotton farm. I've struggled with pest infestations in the past, but these sprays have proven to be highly effective. My crop has never looked healthier, and my yield has gone up significantly. Plus, their customer support is top-notch!"},
        // ... other reviews
        { name: 'Sarah D.', review: "Glee Products cotton sprays have been a game-changer for my cotton farm. I've struggled with pest infestations in the past, but these sprays have proven to be highly effective. My crop has never looked healthier, and my yield has gone up significantly. Plus, their customer support is top-notch!"},

      ];
      const middleIndex = Math.floor(reviews.length / 2);

      // Slider settings
      const settings ={
        centerMode: true,
        centerPadding: "15px 40px",
        dots: true,
        slidesToShow: 3,
        infinite: true,
        arrows: false,
       
    

        responsive: [
          {
            breakpoint: 970,
            settings: {
              slidesToShow: 1,
              centerMode: false
            }
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              centerMode: false
            }
          },
         
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1
            }
          }
        ]
      }
  return (
    <div className='review-section'>
        <div className='review-section-main'>
        <div className='review-section-img'>
        {type==="glee" ? <GleeLogo/> :type==="Nongye" ?<NongyeLogoGreen/>:type==="CQI"?<CQILogoGreen/>:<img src={mainLogo}/>}   

    </div>
    <div className='review-section-text'>
           <Typography fontSize={"35px"} fontWeight={700} color={"#000000"}>
           10,000+ Happy And Satisfactory Farmers
           </Typography>
    </div>
    <div className="slider-container"  >
    <Slider {...settings}>
  {reviews.map((review, index) => (
    <div key={index}>
      <div className='review-card-detail'>
        <Typography sx={{marginTop:"26px"}} fontSize={"30px"} fontWeight={400}>
            {review.name}
        </Typography>
        <img src={Review} alt="Review Rating"/>
        <div className='review-card-detail-text'>
        <Typography fontSize={"16px"} fontWeight={400}>
            {review.review}  
        </Typography >
        </div>
      </div>
    </div>
  ))}
</Slider>
      </div  >
        </div>
     
   
    
    </div>
  )
}

export default ReviewSection