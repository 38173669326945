import React from 'react'

const CQILogoGreen = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="101.662" height="26.709" viewBox="0 0 101.662 26.709">
    <g id="logo-symbol" transform="translate(-0.005 -0.001)">
      <path id="Path_74112" data-name="Path 74112" d="M28.038,10.523a30.359,30.359,0,0,0,.223,3.829,12.783,12.783,0,0,0,.938,3.592,7.638,7.638,0,0,0,2.06,2.8l-1.6-.2A6.481,6.481,0,0,1,25.635,18.4a6.324,6.324,0,0,1-1.611-4.21V6.555a6.324,6.324,0,0,1,1.611-4.21A6.481,6.481,0,0,1,29.656.2l1.6-.2A7.638,7.638,0,0,0,29.2,2.8,12.8,12.8,0,0,0,28.261,6.4,35.69,35.69,0,0,0,28.038,10.523Z" transform="translate(17.189 0.001)" fill="#009035" fill-rule="evenodd"/>
      <path id="Path_74113" data-name="Path 74113" d="M62.65,13.22l39.016,13.422H57.119l-2.352-3.685a9.032,9.032,0,0,0,3.951-1.5l-1.736-1.734a6.257,6.257,0,0,1-3.107.714,5.315,5.315,0,0,1-5.339-3.263,16.675,16.675,0,0,1-.995-6.7,18.7,18.7,0,0,1,.913-6.865A5.321,5.321,0,0,1,53.875.093,5.319,5.319,0,0,1,59.3,3.611a18.7,18.7,0,0,1,.913,6.865,16.67,16.67,0,0,1-.995,6.7,6.578,6.578,0,0,1-.648,1.125l1.784,1.743a8.627,8.627,0,0,0,2.3-5.8ZM53.875,2.807a2.4,2.4,0,0,0-2.414,1.779,18.777,18.777,0,0,0-.63,5.891,16.36,16.36,0,0,0,.671,5.634,2.554,2.554,0,0,0,4.745,0,16.36,16.36,0,0,0,.671-5.634,18.726,18.726,0,0,0-.63-5.891,2.392,2.392,0,0,0-2.414-1.779M44.549,26.642H0L39.019,13.22v1.024A8.908,8.908,0,0,0,46.9,22.957Z" transform="translate(0.002 0.067)" fill="#009035" fill-rule="evenodd"/>
      <path id="Path_74114" data-name="Path 74114" d="M33.354,5.678a3.091,3.091,0,0,1,2.047.767h0a2.956,2.956,0,0,1,.938,1.57A2.937,2.937,0,0,1,38.1,8.72a2.332,2.332,0,0,1,.83,1.758,2.241,2.241,0,0,1-.374,1.235c-.463,1-2.251,1.465-3.344,2.244a12.894,12.894,0,0,1-1.324.849c-.043.515-.065.966-.13,1.477-.043.345-.76.343-.8,0-.065-.511-.087-.962-.13-1.477a12.893,12.893,0,0,1-1.324-.849c-1.091-.779-2.879-1.242-3.344-2.244a2.243,2.243,0,0,1-.376-1.235,2.333,2.333,0,0,1,.832-1.758,2.938,2.938,0,0,1,1.758-.705,2.954,2.954,0,0,1,.937-1.57h0a3.1,3.1,0,0,1,2.047-.767" transform="translate(19.876 4.063)" fill="#58c580" fill-rule="evenodd"/>
    </g>
  </svg>
  
  )
}

export default CQILogoGreen
