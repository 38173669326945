import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  typography: {
    // Define your typography adjustments here
    fontFamily: [
        'Raleway', 
        'sans-serif',
      ].join(','),


  },
  palette: {
    primary: {
      main: '#F4F4F4',
    },
    secondary: {
      main: '#009035',
    },
    error: {
      main: '#ff0000',
    },
 
  },
});