import React from 'react'
import "./index.css"
import ReqButton from '../button/reqButton'
import {  Typography } from '@mui/material';

const Consultation = () => {
  return (
    <div className='consultation-parent'>

<div className='consultation-parent-wrapper'>
        <Typography fontSize={"23px"} color={"#FFFFFF"} fontWeight={700} sx={{maxWidth:"860px"}}>
        Searching For A Knowledgeable Agriculture Consultant To Evaluate Your Farm And Provide Recommendations For Optimal Crop Management?       
         </Typography>
         <ReqButton text={"Request Free Consultation Now →"} color={"#000000"} className={"req-button"}/>

</div>

    </div>
  )
}

export default Consultation