import React from 'react'
import "./index.css"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const clients = new Array(27).fill(null).map((_, index) => require(`../../../assets/images/client${index + 1}.png`));
const settings = {
  dots: false,
  infinite: true,
  slidesToShow: 10,
  slidesToScroll: 1,
  autoplay: true,
  speed: 2000,
  autoplaySpeed: 2000,
  cssEase: "linear",
  arrows: false,
  autoplaySpeed: 0, // Set to 0 for continuous scrolling effect
  cssEase: "linear", // Use linear animation for smooth continuous scrolling
  pauseOnHover: true,
  responsive: [
    {
breakpoint: 1440, // At 1024px and below
      settings: {
        slidesToShow: 8, // Show 5 slides
        slidesToScroll: 1,
       
      }
    },
    {
      breakpoint: 1210, // At 1024px and below
      settings: {
        slidesToShow: 6, // Show 5 slides
        slidesToScroll: 1,
       
      }
    },
    {
      breakpoint: 600, // At 600px and below
      settings: {
        slidesToShow: 3, // Show 2 slides
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 480, // At 480px and below
      settings: {
        slidesToShow: 2, // Show 1 slide
        slidesToScroll: 1
      }
    }]
};

const CertificationSlider = ({ images }) => (

    <Slider {...settings}>
      {images.map((src, index) => (
        <div key={index} className="container">
          <img src={src} alt={`Client ${index + 1}`} />
        </div>
      ))}
    </Slider>
  );
const Client = () => {
  return (
    <div className="mainContainer-client">
    <CertificationSlider images={clients} />
  </div>
  )
}

export default Client
