import React from 'react'

const ReviewStar = () => {
  return (
    <svg id="reviews-5-star" xmlns="http://www.w3.org/2000/svg" width="86.996" height="17.399" viewBox="0 0 86.996 17.399">
    <g id="single-star">
      <path id="Path_33" data-name="Path 33" d="M0,0H17.4V17.4H0Z" fill="none"/>
      <path id="Path_34" data-name="Path 34" d="M0,0H17.4V17.4H0Z" fill="none"/>
      <path id="Path_35" data-name="Path 35" d="M9.25,13.07l4.48,2.7-1.189-5.1L16.5,7.249l-5.212-.442L9.25,2,7.212,6.807,2,7.249l3.958,3.429-1.189,5.1Z" transform="translate(-0.55 -0.55)" fill="#27a55b"/>
    </g>
    <g id="star_black_24dp" transform="translate(34.798)">
      <path id="Path_33-2" data-name="Path 33" d="M0,0H17.4V17.4H0Z" fill="none"/>
      <path id="Path_34-2" data-name="Path 34" d="M0,0H17.4V17.4H0Z" fill="none"/>
      <path id="Path_35-2" data-name="Path 35" d="M9.25,13.07l4.48,2.7-1.189-5.1L16.5,7.249l-5.212-.442L9.25,2,7.212,6.807,2,7.249l3.958,3.429-1.189,5.1Z" transform="translate(-0.55 -0.55)" fill="#27a55b"/>
    </g>
    <g id="star_black_24dp-2" data-name="star_black_24dp" transform="translate(17.399)">
      <path id="Path_33-3" data-name="Path 33" d="M0,0H17.4V17.4H0Z" fill="none"/>
      <path id="Path_34-3" data-name="Path 34" d="M0,0H17.4V17.4H0Z" fill="none"/>
      <path id="Path_35-3" data-name="Path 35" d="M9.25,13.07l4.48,2.7-1.189-5.1L16.5,7.249l-5.212-.442L9.25,2,7.212,6.807,2,7.249l3.958,3.429-1.189,5.1Z" transform="translate(-0.55 -0.55)" fill="#27a55b"/>
    </g>
    <g id="star_black_24dp-3" data-name="star_black_24dp" transform="translate(52.197)">
      <path id="Path_33-4" data-name="Path 33" d="M0,0H17.4V17.4H0Z" fill="none"/>
      <path id="Path_34-4" data-name="Path 34" d="M0,0H17.4V17.4H0Z" fill="none"/>
      <path id="Path_35-4" data-name="Path 35" d="M9.25,13.07l4.48,2.7-1.189-5.1L16.5,7.249l-5.212-.442L9.25,2,7.212,6.807,2,7.249l3.958,3.429-1.189,5.1Z" transform="translate(-0.55 -0.55)" fill="#27a55b"/>
    </g>
    <g id="star_black_24dp-4" data-name="star_black_24dp" transform="translate(69.597)">
      <path id="Path_33-5" data-name="Path 33" d="M0,0H17.4V17.4H0Z" fill="none"/>
      <path id="Path_34-5" data-name="Path 34" d="M0,0H17.4V17.4H0Z" fill="none"/>
      <path id="Path_35-5" data-name="Path 35" d="M9.25,13.07l4.48,2.7-1.189-5.1L16.5,7.249l-5.212-.442L9.25,2,7.212,6.807,2,7.249l3.958,3.429-1.189,5.1Z" transform="translate(-0.55 -0.55)" fill="#27a55b"/>
    </g>
  </svg>
  )
}

export default ReviewStar
