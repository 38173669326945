import React, { useState, useEffect } from 'react';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import { Box, Typography } from '@mui/material';
import './index.css';
import { useSelector } from 'react-redux';
import ModalLocation from '../modal';

const haversineDistance = (coords1, coords2) => {
  function toRad(x) {
    return (x * Math.PI) / 180;
  }

  const lon1 = coords1.lon;
  const lat1 = coords1.lat;

  const lon2 = coords2.lon;
  const lat2 = coords2.lat;

  const R = 6371; // km

  const x1 = lat2 - lat1;
  const dLat = toRad(x1);
  const x2 = lon2 - lon1;
  const dLon = toRad(x2);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRad(lat1)) *
      Math.cos(toRad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const d = R * c;

  return d;
};

const AppBar = ({ userLocation }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleCloseModal = () => {
    setIsModalOpen((isModalOpen) => !isModalOpen);
  };

  const location = useSelector((state) => state.location);

  const infoArray = [
    {
      key: 'Sales Manager: ',
      value: 'Salman Ahmed',
    },
    {
      key: 'Phone No: ',
      value: '(061) 457 900 110',
    },
    {
      key: 'location: ',
      value: '59/3 Abdali Rd, High Court Staff Colony, Multan',
      long: 71.529661,
      lat: 30.167517,
    },
  ];

  const LahoreArray = [
    {
      key: 'Sales Manager: ',
      value: 'Ali',
    },
    {
      key: 'Phone No: ',
      value: '(047) 890 960 000',
    },
    {
      key: 'location: ',
      value: '59/3 Johar Town , Lahore',
      long: 74.33409,
      lat: 31.46775,
    },
  ];

  const bahawalpur = [
    {
      key: 'Sales Manager: ',
      value: 'Ahmed',
    },
    {
      key: 'Phone No: ',
      value: '(047) 890 960 000',
    },
    {
      key: 'location: ',
      value: 'Noor Mahal ,Bahawalpur',
      long: 71.67499,
      lat: 29.38998,
    },
  ];

  const array =
    location === 'Lahore'
      ? LahoreArray
      : location === 'Bahawalpur'
      ? bahawalpur
      : infoArray;

  const [nearestLocation, setNearestLocation] = useState(null);

  useEffect(() => {
    if (userLocation) {
      const currentLocation = {
        lat: userLocation.latitude,
        lon: userLocation.longitude,
      };

      const checkLocations = array.map((loc) => ({
        ...loc,
        distance: haversineDistance(currentLocation, {
          lat: loc.lat,
          lon: loc.long,
        }),
      }));

      const nearbyLocation = checkLocations.find(
        (loc) => loc.distance <= 25
      );

      setNearestLocation(nearbyLocation);
    }
  }, [userLocation, array]);

  return (
    <div className='appBar'>
      <div className='wrapper'>
        <div className='appBar_text'>
          <div className='address_navBar'>
            <FmdGoodIcon />
            <Typography fontSize='12px' fontWeight={600} onClick={handleCloseModal}>
              {location ? location : 'Get Directions To Your Nearest Dealer'}
            </Typography>
          </div>

          <div className='info_navBar'>
            {nearestLocation
              ? array.map((infoData) => (
                  <Box key={infoData.key} gap={'1px'} display={'flex'} alignItems={'center'}>
                    <Typography fontSize={'12px'} fontWeight={600} color={'#000000'}>
                      {infoData.key}
                    </Typography>
                    <Typography fontSize={'12px'} fontWeight={700} color={'#000000'}>
                      {infoData.value}
                    </Typography>
                  </Box>
                ))
              : array.map((infoData) => (
                  <Box key={infoData.key} gap={'1px'} display={'flex'} alignItems={'center'}>
                    <Typography fontSize={'12px'} fontWeight={600} color={'#000000'}>
                      {infoData.key}
                    </Typography>
                    <Typography fontSize={'12px'} fontWeight={700} color={'#000000'}>
                      {infoData.key === 'location: ' ? 'No dealers found' : infoData.value}
                    </Typography>
                  </Box>
                ))}
          </div>
        </div>
      </div>
      {isModalOpen && <ModalLocation isOpen={isModalOpen} onClose={handleCloseModal} />}
    </div>
  );
};

export default AppBar;
