import React from "react";
import "./index.css";
import MiniProduct from "../../assets/svg/miniProduct";
import { Typography } from "@mui/material";
import ReviewStar from "../../assets/svg/reviewStar";
import DownloadIcon from "../../assets/svg/downloadIcon";
import BannerBottomBar from "../../components/bannerBottomBar";
import Certification from "../../components/certificationSection";
import Client from "../../components/certificationSection/clients";
import img1 from "../../assets/images/image-01.png"
import img2 from "../../assets/images/image-03.png"
import img3 from "../../assets/images/image-05.png"
import ReviewSection from "../../components/reviewSection";
import VideoSection from "../../components/videoSection";
import QuestionSection from "../../components/questionSection";
import ImageSection from "../../components/imageSection/imageSection";
import FeedBack from "../../components/feedBackSection";
import Consultation from "../../components/consSection";
import LocationSection from "../../components/locationSection";


const ProductPage = () => {
  return (
    <>
      <div className="product-page">
      <div className="product-page-parent">
        <div className="product-page-image">
          <div className="product-page-mainPage"></div>
          <div className="product-page-moreImage-Parent">
            <div className="product-page-moreImage">
              <MiniProduct />
              <MiniProduct />
              <MiniProduct />
              <MiniProduct />
              <MiniProduct />
            </div>
          </div>
        </div>
        <div className="product-page-text-parent">
          <Typography fontSize={"35px"} fontWeight={700} color={"#000000"}>
            FINEST 5% SL PACK
          </Typography>
          <div className="flex-row">
            <ReviewStar />
            <Typography
              fontSize={"12px"}
              fontWeight={700}
              color={"#000000"}
              sx={{ marginLeft: "5px" }}
            >
              ( 10,000+ Happy And Satisfactory Farmers )
            </Typography>
          </div>
          <div className="banner-data-star-product-text-top">
            <div className="flex-row">
              <Typography fontSize={"16px"} fontWeight={700} color={"#000000"}>
                Product Category:
              </Typography>
              <Typography fontSize={"16px"} fontWeight={600} color={"#000000"}>
                Herbicide
              </Typography>
            </div>
            <div className="flex-row">
              <Typography fontSize={"16px"} fontWeight={700} color={"#000000"}>
                Active Ingredient:
              </Typography>
              <Typography fontSize={"16px"} fontWeight={600} color={"#000000"}>
                5% SL
              </Typography>
            </div>
            <div className="flex-row">
              <Typography fontSize={"16px"} fontWeight={700} color={"#000000"}>
                Formulation:
              </Typography>
              <Typography fontSize={"16px"} fontWeight={600} color={"#000000"}>
                Available in Formulation (60 EC)
              </Typography>
            </div>
            <div className="flex-row-mrgintop">
              <div className="flex-row">
                <Typography
                  fontSize={"16px"}
                  fontWeight={700}
                  color={"#000000"}
                >
                  Target Crop:
                </Typography>
                <Typography
                  fontSize={"16px"}
                  fontWeight={600}
                  color={"#000000"}
                >
                  Rice
                </Typography>
              </div>
              <div className="flex-row">
                <Typography
                  fontSize={"16px"}
                  fontWeight={700}
                  color={"#000000"}
                >
                  Target Pests:
                </Typography>
                <Typography
                  fontSize={"16px"}
                  fontWeight={600}
                  color={"#000000"}
                >
                  Annual grasses and certain broad-leaved weeds in Rice
                </Typography>
              </div>
              <div className="banner-data-star-product-text">
                <Typography
                  fontSize={"16px"}
                  fontWeight={700}
                  color={"#000000"}
                >
                  Mode of Actions:
                </Typography>
                <ul className="herbicide-info-list">
                  <li>
                    <Typography
                      fontSize={"16px"}
                      fontWeight={600}
                      color={"#000000"}
                    >
                      Selective systemic herbicide, absorbed primarily by the
                      germinating shoots and secondarily by the roots, with
                      translocation throughout the plant.
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      fontSize={"16px"}
                      fontWeight={600}
                      color={"#000000"}
                    >
                      Inhibits cell division by blocking protein synthesis.
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      fontSize={"16px"}
                      fontWeight={600}
                      color={"#000000"}
                    >
                      Used as pre/early post-emergence for the control of annual
                      grasses and certain broad-leaved weeds in Rice.
                    </Typography>
                  </li>
                </ul>
              </div>
              <div className="banner-data-star-product-text">
                <Typography
                  fontSize={"16px"}
                  fontWeight={700}
                  color={"#000000"}
                >
                  Recommendations:
                </Typography>
                {/* <div className="recommendation-table">
                  <div className="table-header">
                    <div className="header-cell">Crops</div>
                    <div className="header-cell">Pests</div>
                    <div className="header-cell">Dose</div>
                  </div>
                  <div className="table-row">
                    <div className="row-cell">Rice</div>
                    <div className="row-cell-des">
                      Annual grasses and certain broad-leaved weeds in Rice
                    </div>
                    <div className="row-cell">800 ml / Acre</div>
                  </div>
                </div> */}
                <table className="recommendation-table">
                  <thead>
                    <tr>
                      <th className="header-cell crops">Crops</th>
                      <th className="header-cell pests">Pests</th>
                      <th className="header-cell dose">Dose</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="row-cell crops">Rice</td>
                      <td className="row-cell pests">
                        Annual grasses and certain broad-leaved weeds in Rice
                      </td>
                      <td className="row-cell dose">800 ml / Acre</td>
                    </tr>
                    {/* Additional rows as needed */}
                  </tbody>
                </table>
              </div>
              <div className="banner-data-star-product-text">
                <Typography
                  fontSize={"16px"}
                  fontWeight={700}
                  color={"#000000"}
                >
                  Method of Application::
                </Typography>
                <Typography
                  fontSize={"16px"}
                  fontWeight={600}
                  color={"#000000"}
                >
                  Apply through Shaker bottle, within 5-7 days after
                  transplanting.
                </Typography>
                <Typography
                  fontSize={"16px"}
                  fontWeight={600}
                  color={"#000000"}
                >
                  Water should stand 3 - 4 inches at time of application.
                </Typography>
                <Typography
                  fontSize={"16px"}
                  fontWeight={600}
                  color={"#000000"}
                >
                  There should be water stand in the field till 5 - 7 days after
                  Butachlor application.
                </Typography>
                <Typography
                  fontSize={"16px"}
                  fontWeight={600}
                  color={"#000000"}
                >
                  Registration Details: Product is registered in Pakistan under
                  form-1B.
                </Typography>
              </div>

              <ul className="herbicide-info-list-download">
              <div className="list-content">
              <li>
                  <a
                    href="/path-to-your-pdf.pdf"
                    download
                    className="download-button"
                  >
                    <Typography
                      fontSize={"16px"}
                      fontWeight={600}
                      color={"#009035"}
                    >
                      Download PDF
                    </Typography>
                  </a>
                </li>
                <DownloadIcon />
                </div>
              <div className="list-content">
              <li>
                  <a
                    href="/path-to-your-pdf.pdf"
                    download
                    className="download-button"
                  >
                    <Typography
                      fontSize={"16px"}
                      fontWeight={600}
                      color={"#009035"}
                    >
                      View PPT
                    </Typography>
                  </a>

                 
                </li>
                <DownloadIcon />
              </div>
                
              </ul>
            </div>
          </div>
        </div>
      </div>
   
    </div>
       <BannerBottomBar />
       <div className="certificate-section">
       <Client/>
       </div>
       <div className="Experience-Section">
            <div className="Experience-Section-text">
            <Typography fontWeight={700} fontSize={"35px"} color={"#000000"}>
            Experience Unbeatable Benefits
            </Typography>
            </div>
            <div className="Experience-Parent">
            <div className="Experience-Section-row">
               <img src={img1} alt="img1"/>
               <div className="Experience-Section-col-text">
               <Typography fontWeight={600} fontSize={"18px"} color={"#000000"}>
               Benefit 01
            </Typography>
            <Typography fontWeight={600} fontSize={"18px"} color={"#000000"}>
            Our “Best Quality Or Your Money Back”
            </Typography>
            <Typography fontWeight={400} fontSize={"18px"} color={"#6D6D6D"} sx={{maxWidth:"592px"}}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed pretium risus non tortor ullamcorper dictum. Fusce id facilisis purus. Proin nec lorem eu nunc aliquet bibendum. In tincidunt pellentesque convallis. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Nullam ut nunc nec risus auctor efficitur. Nam id turpis sed purus ullamcorper tempor in id risus.<br/> Nulla facilisi. Nunc aliquam consectetur consectetur. Vivamus rhoncus venenatis nibh, nec bibendum mauris tristique sit amet. Sed lacinia posuere sapien.
            </Typography>
               </div>
            </div>
            <div className="Experience-Section-row">
              
               <div className="Experience-Section-col-text">
               <Typography fontWeight={600} fontSize={"18px"} color={"#000000"}>
               Benefit 02
            </Typography>
            <Typography fontWeight={600} fontSize={"18px"} color={"#000000"}>
            We Offer Lifetime Warranties.
            </Typography>
            <Typography fontWeight={400} fontSize={"18px"} color={"#6D6D6D"} sx={{maxWidth:"592px"}}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed pretium risus non tortor ullamcorper dictum. Fusce id facilisis purus. Proin nec lorem eu nunc aliquet bibendum. In tincidunt pellentesque convallis. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Nullam ut nunc nec risus auctor efficitur. Nam id turpis sed purus ullamcorper tempor in id risus.<br/> Nulla facilisi. Nunc aliquam consectetur consectetur. Vivamus rhoncus venenatis nibh, nec bibendum mauris tristique sit amet. Sed lacinia posuere sapien.
            </Typography>
               </div>
               <img src={img2} alt="img1"/>
            </div>
            <div className="Experience-Section-row">
               <img src={img3} alt="img1"/>
               <div className="Experience-Section-col-text">
               <Typography fontWeight={600} fontSize={"18px"} color={"#000000"}>
               Benefit 03
            </Typography>
            <Typography fontWeight={400} fontSize={"18px"} color={"#6D6D6D"} sx={{maxWidth:"592px"}}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed pretium risus non tortor ullamcorper dictum. Fusce id facilisis purus. Proin nec lorem eu nunc aliquet bibendum. In tincidunt pellentesque convallis. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Nullam ut nunc nec risus auctor efficitur. Nam id turpis sed purus ullamcorper tempor in id risus.<br/> Nulla facilisi. Nunc aliquam consectetur consectetur. Vivamus rhoncus venenatis nibh, nec bibendum mauris tristique sit amet. Sed lacinia posuere sapien.
            </Typography>
               </div>
            </div>
            </div>
          
       </div>
       <ReviewSection/>
       <VideoSection/>
       <QuestionSection />
         <ImageSection />
         <div className='banner-Bottom-below'>
         <BannerBottomBar />
         </div>
        <FeedBack />
      
        <Consultation />
       <LocationSection />
       <BannerBottomBar />
    </>
  
  );
};

export default ProductPage;
