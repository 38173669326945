import React from 'react'
import "./index.css"
import "../questionSection/index.css"
import {  Typography } from '@mui/material';
import ReqButton from '../button/reqButton';
import star from "../../assets/images/reviews-5-star.png"
const FeedBack = () => {
  return (
  
    <div className='feedback-section'>
          <Typography fontSize={"35px"} fontWeight={700} color={"#000000"} sx={{marginBottom:"15px"}}>
          Share Your Feedback and Suggestions
          </Typography>
          <Typography fontSize={"22px"} fontWeight={600} color={"#000000"} sx={{marginBottom:"72px",maxWidth:"940px",textAlign:"center"}}>
          Reach out to us regarding any agriculture need that you have and Fazal Group of Comapnies will get back to you as soon as possible!       
          </Typography>
    <form className='formContainer'>
        <div className='rowInputField'> 
        <input type="text" id="fname" name="fname" placeholder="Name" className='input-field'/>
        <input type="text" id="email" name="email" placeholder="Email" className='input-field'/>

        </div>
        <div className='rowInputField'> 
        <input type="text" id="phoneNumber" name="phoneNumber" placeholder="PhoneNumber" className='input-field'/>
        <input type="text" id="city" name="city" placeholder="City" className='input-field'  />
        </div>
        <textarea id="Message" name="Message" rows="12" cols="100" placeholder="Message"  className='area-field'></textarea>
    </form>
    <div className='parent-feedback-section'>
        <div className='question-product-button-about'> 
         <ReqButton text={"Reach Out To Us Now!"} color={"#000000"} className={"product-button"}/>
         </div>
         <div className='question-product-overall-rating'>
         <img src={star}/>
             <Typography fontSize={"14px"} fontWeight={600} color={"#000000"} sx={{marginLeft:"5px"}}>
             ( 10,000+ Happy And Satisfactory Farmers )
             </Typography>
         </div>
        </div>
    </div>
  )
}

export default FeedBack