import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  location: "",
}
console.log("state",initialState.location)

export const locationSlice = createSlice({
  name: 'locationSelector',
  initialState,
  reducers: {
    saveLocation: (state,action) => {

      state.location=action.payload
    },
   
  },
})

// Action creators are generated for each case reducer function
export const { saveLocation } = locationSlice.actions

export default locationSlice.reducer