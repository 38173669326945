import React from 'react'
import "./index.css"
import vid3img from "../../assets/images/video-01.png"
import vid2img from "../../assets/images/video-02.png"
import vid5img from "../../assets/images/video-03.png"
import vid4img from "../../assets/images/video-04.png"
import vid1img from "../../assets/images/video-05.png"
import VideoCard from './videoCard'
import {  Typography } from '@mui/material';

const array=[{
  title:"Sadiqbad Rahim Yar Khan | Citrus Orchard",
  img:vid1img,
  para:"HEIS Video | Hamaray Kaashtkaar Ki Kahani | Hassan Abdullah, Farm Owner, Sadiqbad Rahim Yar Khan, Sharing The Experience Of Growing Citrus Fruits With The Help Of The Drip Irrigation System."
},
{
  title:"Sadiqbad Rahim Yar Khan | Citrus Orchard",
  img:vid2img,
  para:"HEIS Video | Hamaray Kaashtkaar Ki Kahani | Hassan Abdullah, Farm Owner, Sadiqbad Rahim Yar Khan, Sharing The Experience Of Growing Citrus Fruits With The Help Of The Drip Irrigation System."
},
{
  title:"Sadiqbad Rahim Yar Khan | Citrus Orchard",
  img:vid3img,
  para:"HEIS Video | Hamaray Kaashtkaar Ki Kahani | Hassan Abdullah, Farm Owner, Sadiqbad Rahim Yar Khan, Sharing The Experience Of Growing Citrus Fruits With The Help Of The Drip Irrigation System."
},
{
  title:"Sadiqbad Rahim Yar Khan | Citrus Orchard",
  img:vid4img,
  para:"HEIS Video | Hamaray Kaashtkaar Ki Kahani | Hassan Abdullah, Farm Owner, Sadiqbad Rahim Yar Khan, Sharing The Experience Of Growing Citrus Fruits With The Help Of The Drip Irrigation System."
},
{
  title:"Sadiqbad Rahim Yar Khan | Citrus Orchard",
  img:vid5img,
  para:"HEIS Video | Hamaray Kaashtkaar Ki Kahani | Hassan Abdullah, Farm Owner, Sadiqbad Rahim Yar Khan, Sharing The Experience Of Growing Citrus Fruits With The Help Of The Drip Irrigation System."
}
]
const VideoSection = () => {
  return (
    <div className='wrapper'>
    <div className='video-section'>

        <div className='video-section-testimonials'>
        <Typography fontWeight={700} fontSize={"35px"} color={"#000000"}>
        Video Testimonials
        </Typography>
        </div>
      
        <div className='video-section-parent'>
        {
          array.map((data)=>{
            return (
              <VideoCard image={data.img} headingText ={data.title} paraText={data.para} />
            )
          })
        }
       
        </div>
    </div>

    </div>
  )
}

export default VideoSection